import { BimApi, BimApiLoadOptionsFactory, BimDevApiClient } from '@twinfinity/core';

const main = async () => {
    // alert('350cc98');
    const r = await fetch('./dist/dev-files.json');
    const devFiles = await r.json();
    const client = new BimDevApiClient(devFiles);
    const container = await client.getContainers();
    const api = await BimApi.create('viewer', client);

    // api.camera.attachBehavior(PostProcessEffects.createLineShading());
    // api.camera.attachBehavior(PostProcessEffects.createSSAOPipeline(46, 1.0, 16, 0.08, 1.0));
    await api.setContainer(container[0], BimApiLoadOptionsFactory.all());
};

main();
